import { useEffect, useState } from 'react';
import enUS from 'antd/es/locale/en_US';
import { uiHelpers } from 'utils/helpers/uiHelpers';

export const useGetCurrentAntdLocale = () => {
    const [locale, setLocale] = useState(enUS);

    useEffect(() => {
        const userLocale = uiHelpers.getBrowserLocale();

        setLocale(userLocale);
    }, []);

    return locale;
};
