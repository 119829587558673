import React, { useState } from 'react';
import { userSelectors } from 'store/user/userSelectors';
import { useUser } from 'store/user/useUser';
import { authConstants } from 'utils/constants/auth';
import { locationHelpers } from 'utils/helpers/locationHelpers';

import PageLoader from 'components/ui/PageLoader/PageLoader';
import { useOnce } from 'components/utils/hooks/useOnce';

interface IProtectedPage {
    children: JSX.Element;
}

export const ProtectedPage: React.FC<IProtectedPage> = (props) => {
    const { children } = props;

    const isLoading = useUser(userSelectors.isLoading);
    const getUserInfo = useUser(userSelectors.getUserInfo);

    const [view, setIsView] = useState(false);

    useOnce(
        () => true,
        async () => {
            const user = await getUserInfo();

            if (!user.authorized) {
                return locationHelpers.replaceLocation(
                    authConstants.REDIRECT_URL,
                );
            }

            return setIsView(true);
        },
        [],
    );

    if (!view || isLoading) {
        return <PageLoader />;
    }

    return children;
};
