import React, { useRef } from 'react';
import type { DependencyList } from 'react';

export const useEffectWithoutInitCall = (
    effect: () => void,
    deps: DependencyList,
) => {
    const isInitialRender = useRef(true);

    React.useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            effect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
