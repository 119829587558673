import { useEffect, useRef, useState } from 'react';

export function useAbbreviatedText(
    text: string,
    formatFunction: (text: string) => string,
) {
    const [displayText, setDisplayText] = useState(text);

    const containerRef = useRef<HTMLDivElement>(null);
    const hiddenRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const containerEl = containerRef.current;
        const hiddenEl = hiddenRef.current;

        if (containerEl && hiddenEl) {
            const measure = () => {
                const fullWidth = hiddenEl.offsetWidth;
                const availableWidth = containerEl.clientWidth;

                if (fullWidth > availableWidth) {
                    setDisplayText(formatFunction(text));
                } else {
                    setDisplayText(text);
                }
            };

            measure();

            const resizeObserver = new ResizeObserver(() => {
                measure();
            });

            resizeObserver.observe(containerEl);

            return () => {
                resizeObserver.disconnect();
            };
        }

        return;
    }, [text, formatFunction]);

    return { displayText, containerRef, hiddenRef };
}
