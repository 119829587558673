import type { THistorySettlement } from 'models/Merchant/HistorySettlement';
import type { TTableColumn } from 'models/Table';

import { Type } from './components/Type';
import { settlementsContentHelpers } from './helpers';

export const COLUMNS: TTableColumn<THistorySettlement>[] = [
    {
        key: 'identity',
        title: 'balance.historySettlementsTable.identity',
        dataIndex: 'identity',
    },
    {
        key: 'currency',
        title: 'balance.historySettlementsTable.currency',
        dataIndex: 'currency',
    },
    {
        key: 'type',
        title: 'balance.historySettlementsTable.type',
        dataIndex: 'type',
        render: (_, record) => <Type type={record.type} />,
    },
    {
        key: 'amount',
        title: 'balance.historySettlementsTable.amount',
        dataIndex: 'amount',
    },
    {
        key: 'amountUsd',
        title: 'balance.historySettlementsTable.amountUsd',
        dataIndex: 'amountUsd',
    },
    {
        key: 'commission',
        title: 'balance.historySettlementsTable.commissionAmount',
        dataIndex: 'commission',
    },
    {
        key: 'createdAt',
        title: 'balance.historySettlementsTable.createdAt',
        dataIndex: 'createdAt',
        render: (_, record) =>
            settlementsContentHelpers.getDate(record.createdAt),
    },
    {
        key: 'comment',
        title: 'balance.historySettlementsTable.comment',
        dataIndex: 'comment',
    },
];
