import { AbbreviatedText } from 'ui/AbbreviatedText';
import { invoicesHelpers } from 'utils/helpers/invoices';
import type { TComponent } from 'utils/types/component';

import type { IPaymentInvoice } from 'models/Invoice/Payment';

import styles from './ExternalIdentityCell.module.scss';

interface IExternalIdentityCellProps {
    record: IPaymentInvoice;
}

export const ExternalIdentityCell: TComponent<IExternalIdentityCellProps> = (
    props,
) => {
    const { record } = props;

    const identity = record.externalIdentity;

    return (
        <AbbreviatedText
            text={identity}
            formatFunction={invoicesHelpers.formatIdentity}
            className={styles.text}
        />
    );
};
