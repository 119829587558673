import React, { Suspense } from 'react';

import PageLoader from 'components/ui/PageLoader/PageLoader';

import type { Pages } from 'models/Pages';

interface IAsyncPageProps {
    page: Pages;
}

function asyncImport(page: Pages) {
    return import(`../../../pages/${page}/${page}`);
}

const AsyncPage: React.FC<IAsyncPageProps> = ({ page }) => {
    const PageComponent = React.lazy(() => asyncImport(page));

    return (
        <Suspense fallback={<PageLoader />}>
            <PageComponent />
        </Suspense>
    );
};

export { AsyncPage };
