import type { TablePaginationConfig, TableProps } from 'antd';
import { Table } from 'antd';
import type {
    FilterValue,
    SorterResult,
    TableCurrentDataSource,
} from 'antd/es/table/interface';
import type { ExpandableConfig } from 'rc-table/lib/interface';
import { ORDER_FROM_ANTD_MAP } from 'utils/constants/tables/tables';

import { useTableEnhancements } from 'components/utils/hooks/useTableEnhancements';
import { useTTableColumns } from 'components/utils/hooks/useTTableColumns';

import type { TTableColumn, TTableSort } from 'models/Table';

interface ITableProps<TRow extends object, TColumn extends TTableColumn<TRow>>
    extends Omit<TableProps<TRow>, 'onChange'> {
    columns: TColumn[];
    tableRows: TRow[];
    onChange: (
        current: number,
        sortParams: TTableSort<TRow>,
        additionalOptions: {
            pagination: TablePaginationConfig;
            filters: Record<string, FilterValue | null>;
            sorter: SorterResult<TRow> | SorterResult<TRow>[];
            extra: TableCurrentDataSource<TRow>;
        },
    ) => void;
    isResizable?: boolean;
    expandable?: ExpandableConfig<TRow>;
    onRowClick?: (record: TRow) => void;
    rowKey?: (record: TRow) => string | number;
}

const _Table = <TRow extends object, TColumn extends TTableColumn<TRow>>(
    props: ITableProps<TRow, TColumn>,
) => {
    const {
        tableRows,
        columns,
        onRowClick,
        expandable,
        rowKey,
        onChange,
        components: userComponents,
        isResizable = false,
        ...tableProps
    } = props;

    const tColumns = useTTableColumns<TRow, TColumn>(columns);

    const { columns: enhancedColumns, components } = useTableEnhancements<
        TRow,
        TColumn
    >(tColumns, {
        isResizable,
        components: userComponents,
    });

    const handleChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<TRow> | SorterResult<TRow>[],
        extra: TableCurrentDataSource<TRow>,
    ) => {
        const { current = 1 } = pagination;
        const sortParams = {} as TTableSort<TRow>;

        if (sorter.hasOwnProperty('column')) {
            const { columnKey, order } = sorter as SorterResult<TRow>;

            const columnKeyString = columnKey?.toString();

            if (columnKeyString) {
                sortParams[columnKeyString] =
                    order && ORDER_FROM_ANTD_MAP[order];
            }
        }

        onChange(current, sortParams, { filters, sorter, extra, pagination });
    };

    const handleRowClick = (record: TRow) => ({
        onClick: () => onRowClick?.(record),
    });

    return (
        <Table
            rowKey={rowKey}
            columns={enhancedColumns}
            components={components}
            dataSource={tableRows}
            onChange={handleChange}
            onRow={onRowClick && handleRowClick}
            expandable={expandable}
            pagination={false}
            {...tableProps}
        />
    );
};

export default _Table;
