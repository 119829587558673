import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { TTableColumn } from 'models/Table';

export const useTTableColumns = <
    TRow extends object,
    TColumn extends TTableColumn<TRow> = TTableColumn<TRow>,
>(
    columns: TColumn[],
): TColumn[] => {
    const { t } = useTranslation();

    return useMemo(
        () =>
            columns.map((column) => ({
                ...column,
                title: t(`${column.title}`),
            })),
        [columns, t],
    );
};
