import { type ReactNode, useCallback } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';

import { useCopyToClipboard } from 'components/utils/hooks/useCopyToClipboard';

import styles from './Clipboard.module.scss';

interface IClipboardProps {
    children: ReactNode;
    textToCopy: string | number;
    className?: string;
}

const Clipboard: TComponent<IClipboardProps> = (props) => {
    const { textToCopy, children, className } = props;

    const { t } = useTranslation();
    const [copy] = useCopyToClipboard();

    const handleClick = useCallback(async () => {
        await copy(String(textToCopy));
        message.success(t('common.messages.copied'));
    }, [copy, textToCopy]);

    return (
        <div className={cn(styles.root, className)}>
            {children}
            <CopyOutlined onClick={handleClick} className={styles.icon} />
        </div>
    );
};

export default Clipboard;
