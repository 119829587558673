import { useEffect, useRef } from 'react';

/**
 * Хук для отслеживания видимости страницы.
 * @param onVisible - Коллбек, который вызывается при возврате на страницу.
 */
export const usePageVisibility = (onVisible: () => void): void => {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = onVisible;
    }, [onVisible]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (
                document.visibilityState === 'visible' &&
                savedCallback.current
            ) {
                savedCallback.current();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange,
            );
        };
    }, []);
};
