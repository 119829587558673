import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';

import type { THistorySettlement } from 'models/Merchant/HistorySettlement';

import { settlementsContentHelpers } from '../../helpers';

interface ITypeProps {
    type: THistorySettlement['type'];
}

export const Type: TComponent<ITypeProps> = (props) => {
    const { type } = props;

    const { t } = useTranslation();

    const key = settlementsContentHelpers.getType(type);

    return <>{t(key)}</>;
};
