import { useState } from 'react';
import type { TableComponents } from 'rc-table/lib/interface';

import { TableCustomCell } from 'components/ui/TableCustomCell';

import type { TTableColumn } from 'models/Table';

interface ITableEnhancementsOptions<TRow> {
    isResizable?: boolean;
    components?: TableComponents<TRow>;
}

interface IUseTableEnhancementsResult<TRow, TColumn> {
    columns: TColumn[];
    components?: TableComponents<TRow>;
}

const calculateMinWidth = (
    title: string,
    averageCharWidth: number = 10,
    padding: number = 40,
): number => {
    return title.length * averageCharWidth + padding;
};

export function useTableEnhancements<
    TRow extends object,
    TColumn extends TTableColumn<TRow>,
>(
    initialColumns: TColumn[],
    options: ITableEnhancementsOptions<TRow>,
): IUseTableEnhancementsResult<TRow, TColumn> {
    const { isResizable = false, components: userComponents = {} } = options;

    const minWidths = initialColumns.map(
        (col, index) =>
            initialColumns[index].width ??
            calculateMinWidth(col.title as string),
    );

    const [colsWidths, setColsWidths] = useState<(string | number)[]>(
        initialColumns.map((col, index) => col.width || minWidths[index]),
    );

    const handleResize = (index: number) => (newWidth: number) => {
        if (Number(minWidths[index]) > newWidth) {
            return;
        }

        setColsWidths((prevWidths) => {
            const nextWidths = [...prevWidths];
            nextWidths[index] = newWidth;
            return nextWidths;
        });
    };

    const columns = initialColumns.map((col, index) => {
        const updatedCol = {
            ...col,
            width: colsWidths[index],
        };

        if (isResizable) {
            return {
                ...updatedCol,
                onHeaderCell: () => ({
                    width: colsWidths[index],
                    onResize: handleResize(index),
                }),
            };
        }

        return updatedCol;
    });

    let components = userComponents;

    if (isResizable) {
        components = {
            ...components,
            header: {
                ...components.header,
                cell: TableCustomCell,
            },
        };
    }

    return {
        columns,
        components,
    };
}
