import { cloneElement } from 'react';
import { Tag } from 'antd';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { invoicesHelpers } from 'utils/helpers/invoices';
import type { TComponent } from 'utils/types/component';

import type { IPayoutInvoice } from 'models/Invoice/Payout';
import { PAYOUT_INVOICE_STATUS_INVOICE } from 'models/Invoice/Payout';

interface IStatusProps {
    status: IPayoutInvoice['status'];
}

const statusMap: Record<IPayoutInvoice['status'], ReactElement> = {
    [PAYOUT_INVOICE_STATUS_INVOICE.DECLINED]: <Tag color='red' />,
    [PAYOUT_INVOICE_STATUS_INVOICE.PROCESSING]: <Tag color='orange' />,
    [PAYOUT_INVOICE_STATUS_INVOICE.SUCCEED]: <Tag color='green' />,
    [PAYOUT_INVOICE_STATUS_INVOICE.CANCELED]: <Tag color='red' />,
    [PAYOUT_INVOICE_STATUS_INVOICE.NEW]: <Tag color='blue' />,
};

export const Status: TComponent<IStatusProps> = (props) => {
    const { status } = props;

    const { t } = useTranslation();

    const key = invoicesHelpers.getPayoutStatus(status);

    const tagElement = statusMap[status];

    return tagElement ? cloneElement(tagElement, { children: t(key) }) : null;
};
