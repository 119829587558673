import { ConfigProvider } from 'antd';
import type { ReactNode } from 'react';
import type { TComponent } from 'utils/types/component';

import { useGetCurrentAntdLocale } from 'components/utils/hooks/useGetCurrentAntdLocale';

interface LocalizedConfigProviderProps {
    children: ReactNode;
}

export const LocalizedConfigProvider: TComponent<
    LocalizedConfigProviderProps
> = ({ children }) => {
    const locale = useGetCurrentAntdLocale();

    return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};
