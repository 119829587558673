import { useCallback, useState } from 'react';

type TCopiedValue = string | null;
type TCopyFn = (text: string) => Promise<boolean>; // Return success

export const useCopyToClipboard = (): [TCopyFn, TCopiedValue] => {
    const [copiedText, setCopiedText] = useState<TCopiedValue>(null);

    const copy: TCopyFn = useCallback(async (text) => {
        if (!navigator?.clipboard) {
            return false;
        }

        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            return true;
        } catch (error) {
            setCopiedText(null);
            return false;
        }
    }, []);

    return [copy, copiedText];
};
