import { Button } from 'antd';
import type { TComponent } from 'utils/types/component';

import styles from './ExportButton.module.scss';

interface IExportButtonProps {
    onClick: () => void;
}

export const ExportButton: TComponent<IExportButtonProps> = (props) => {
    const { onClick } = props;

    return (
        <Button onClick={onClick} className={styles.exportButton}>
            Экспорт
        </Button>
    );
};
