import { Spin } from 'antd';
import type { PropsWithChildren } from 'react';
import type { TComponent } from 'utils/types/component';

import styles from './Loader.module.scss';

interface ILoaderProps {
    active: boolean;
    hideContentByActive?: boolean;
}

const Loader: TComponent<PropsWithChildren<ILoaderProps>> = (props) => {
    const { children, active, hideContentByActive = false } = props;

    const hideContent = hideContentByActive && active;

    return (
        <div className={styles.root}>
            {!hideContent && children}
            {active && (
                <div className={styles.loaderWrapper}>
                    <Spin size='large' />
                </div>
            )}
        </div>
    );
};

export default Loader;
