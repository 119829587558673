import { INVOICES_CONSTANTS } from 'utils/constants/invoices';
import { dateHelpers } from 'utils/helpers/dateHelpers';

import type { IPaymentInvoice } from 'models/Invoice/Payment';
import type { TTableColumn } from 'models/Table';

import { ExternalIdentityCell } from './components/ExternalIdentityCell';
import { IdentityCell } from './components/IdentityCell';
import { Status } from './components/Status';
import { UserIdCell } from './components/UserIdCell';

export const COLUMNS: TTableColumn<IPaymentInvoice>[] = [
    {
        dataIndex: 'identity',
        key: 'identity',
        title: 'invoices.payment.table.id',
        width: 135,
        render: (_, record) => <IdentityCell record={record} />,
    },
    {
        dataIndex: 'userId',
        key: 'userId',
        title: 'invoices.payment.table.userId',
        width: 125,
        render: (_, record) => <UserIdCell record={record} />,
    },

    {
        dataIndex: 'externalIdentity',
        key: 'externalIdentity',
        title: 'invoices.payment.table.externalIdentity',
        width: 170,
        render: (_, record) => <ExternalIdentityCell record={record} />,
    },

    {
        dataIndex: 'siteName',
        key: 'siteName',
        title: 'invoices.payment.table.siteName',
    },

    {
        dataIndex: 'methodCode',
        key: 'methodCode',
        title: 'invoices.payment.table.methodName',
    },

    {
        dataIndex: 'amount',
        key: 'amount',
        title: 'invoices.payment.table.amount',
    },
    {
        dataIndex: 'currencyCode',
        key: 'currencyCode',
        title: 'invoices.payment.table.currency',
    },
    {
        dataIndex: 'status',
        key: 'status',
        title: 'invoices.payment.table.status',
        render: (_, record) => <Status status={record.status} />,
    },
    {
        dataIndex: 'createdAt',
        key: 'createdAt',
        title: 'invoices.payment.table.createdAt',
        render: (_, record) =>
            record.createdAt
                ? dateHelpers.formatToLocalTimezone(
                      record.createdAt,
                      INVOICES_CONSTANTS.VIEW_DATE_FORMAT,
                  )
                : '',
    },
    {
        dataIndex: 'finishedAt',
        key: 'finishedAt',
        title: 'invoices.payment.table.finishedAt',
        render: (_, record) =>
            record.finishedAt
                ? dateHelpers.formatToLocalTimezone(
                      record.finishedAt,
                      INVOICES_CONSTANTS.VIEW_DATE_FORMAT,
                  )
                : '',
    },
];
