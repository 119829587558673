import type { TPayoutInvoiceFindBody } from 'api/invoices/payout/types';
import { INVOICES_CONSTANTS } from 'utils/constants/invoices';
import { dateHelpers } from 'utils/helpers/dateHelpers';

import type { IFilterForm } from '../types';

const getFiltersData = (data: IFilterForm) => {
    if (!Object.keys(data).length) {
        return;
    }

    const result: TPayoutInvoiceFindBody = {};

    if (data.searchStr) {
        result.searchStr = data.searchStr;
    }

    if (data.externalUserId) {
        result.externalUserId = data.externalUserId;
    }

    if (data.externalIdentity) {
        result.externalIdentity = data.externalIdentity;
    }

    if (data.currencyCode) {
        result.currencyCode = data.currencyCode;
    }

    if (data.statuses) {
        result.statuses = data.statuses;
    }

    if (data.methodCodes) {
        result.methodCodes = data.methodCodes;
    }

    if (data.siteIdentities) {
        result.siteIdentities = data.siteIdentities;
    }

    if (data.createdAtFrom || data.createdAtTo) {
        if (!result.dateFilter) {
            result.dateFilter = { createdAtFrom: '', createdAtTo: '' };
        }

        if (data.createdAtFrom) {
            result.dateFilter.createdAtFrom = dateHelpers.formatTo(
                dateHelpers.formatToUtc(data.createdAtFrom).toISOString(),
                INVOICES_CONSTANTS.FILTER_DATE_FORMAT,
            );
        }

        if (data.createdAtTo) {
            result.dateFilter.createdAtTo = dateHelpers.formatTo(
                dateHelpers.formatToUtc(data.createdAtTo).toISOString(),
                INVOICES_CONSTANTS.FILTER_DATE_FORMAT,
            );
        }
    }

    if (data.amountFrom || data.amountTo) {
        if (!result.amountFilter) {
            result.amountFilter = {};
        }

        if (data.amountFrom) {
            result.amountFilter.amountFrom = data.amountFrom;
        }

        if (data.amountTo) {
            result.amountFilter.amountTo = data.amountTo;
        }
    }

    return result;
};

export const filtersHelpers = { getFiltersData };
