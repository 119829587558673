import React from 'react';
import { Spin } from 'antd';

import styles from './PageLoader.module.scss';

const PageLoader: React.FC = () => {
    return (
        <div className={styles.loaderWrapper}>
            <Spin size='large' />
        </div>
    );
};

export default PageLoader;
