import { dateConstants } from 'utils/constants/date';
import { SETTLEMENTS_CONSTANTS } from 'utils/constants/settlements';
import { dateHelpers } from 'utils/helpers/dateHelpers';

import type { TSettlementTypes } from 'models/Merchant/HistorySettlement';

export const getDate = (date: string) => {
    if (!date) {
        return date;
    }

    return dateHelpers.formatTo(date, dateConstants.FULL_DATE_FORMAT);
};

export const getType = (type: TSettlementTypes) => {
    switch (type) {
        case SETTLEMENTS_CONSTANTS.SETTLEMENT_TYPE.PAYMENT:
            return 'balance.historySettlementsTable.payment';
        case SETTLEMENTS_CONSTANTS.SETTLEMENT_TYPE.PAYOUT:
            return 'balance.historySettlementsTable.payout';
    }
};

export const settlementsContentHelpers = {
    getDate,
    getType,
};
