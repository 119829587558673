import { Pagination } from 'antd';
import { historySettlementsTableFiltersSelectors } from 'store/merchants/historySettlementsTableFiltersSelectors';
import { historySettlementsTableSelectors } from 'store/merchants/historySettlementsTableSelectors';
import { useHistorySettlementsTable } from 'store/merchants/useHistorySettlementsTable';
import { useHistorySettlementsTableFilters } from 'store/merchants/useHistorySettlementsTableFilters';
import { LocalizedConfigProvider } from 'ui/LocalizedConfigProvider';
import type { TComponent } from 'utils/types/component';

export const CustomPagination: TComponent = () => {
    const params = useHistorySettlementsTableFilters(
        historySettlementsTableFiltersSelectors.getParams,
    );
    const body = useHistorySettlementsTableFilters(
        historySettlementsTableFiltersSelectors.getBody,
    );
    const totalElements = useHistorySettlementsTable(
        historySettlementsTableSelectors.totalElements,
    );

    const setParams = useHistorySettlementsTableFilters(
        historySettlementsTableFiltersSelectors.setParams,
    );
    const getTable = useHistorySettlementsTable(
        historySettlementsTableSelectors.getTable,
    );

    const page = params.page + 1;

    const handleChangePage = (currentPage: number, pageSize: number) => {
        const enhancePage = currentPage - 1;

        const newParams = { ...params, page: enhancePage, size: pageSize };

        setParams(newParams);
        getTable(body, newParams);
    };

    return (
        <LocalizedConfigProvider>
            <Pagination
                showSizeChanger
                current={page}
                total={totalElements}
                onChange={handleChangePage}
            />
        </LocalizedConfigProvider>
    );
};
