import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import type { InputProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import type { TComponent } from 'utils/types/component';
import type { TInputChangeEvent } from 'utils/types/ui';

import styles from './Search.module.scss';

interface ISearchProps extends Omit<InputProps, 'onChange'> {
    onChange?: (value: string) => void;
}

export const Search: TComponent<ISearchProps> = (props) => {
    const { t } = useTranslation();
    const {
        placeholder = t('common.form.search'),
        onChange,
        ...otherProps
    } = props;

    const handleChange = (e: TInputChangeEvent) => {
        if (typeof onChange === 'function') {
            onChange(e.target.value);
        }
    };

    return (
        <Input
            prefix={<SearchOutlined className={styles.searchIcon} />}
            {...otherProps}
            placeholder={placeholder}
            onChange={handleChange}
        />
    );
};
