import React from 'react';
import { Tooltip, Typography } from 'antd';
import cn from 'classnames';

import { useAbbreviatedText } from 'components/utils/hooks/useAbbreviatedText';

import styles from './AbbreviatedText.module.scss';

interface IAbbreviatedTextProps {
    text: string;
    formatFunction: (text: string) => string;
    tooltipTitle?: string;
    className?: string;
}

export const AbbreviatedText: React.FC<IAbbreviatedTextProps> = ({
    text,
    formatFunction,
    tooltipTitle,
    className,
}) => {
    const { displayText, containerRef, hiddenRef } = useAbbreviatedText(
        text,
        formatFunction,
    );

    return (
        <Tooltip title={tooltipTitle || text}>
            <Typography
                className={cn(styles.text, className)}
                ref={containerRef}
            >
                {displayText}
                <span ref={hiddenRef} className={styles.hidden}>
                    {text}
                </span>
            </Typography>
        </Tooltip>
    );
};
