import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import type { TComponent } from 'utils/types/component';
import type { TDivMouseEvent } from 'utils/types/ui';

import styles from './TableCustomCell.module.scss';

interface ITableCustomCellProps {
    width: number;
    children: React.ReactNode;
    onResize: (width: number) => void;
    className?: string;
}

export const TableCustomCell: TComponent<ITableCustomCellProps> = (props) => {
    const { onResize, width, children, className, ...restProps } = props;

    const [dragging, setDragging] = useState(false);

    const startX = useRef(0);
    const currentWidth = useRef(width);

    const handleMouseDown = (e: TDivMouseEvent) => {
        e.stopPropagation();
        startX.current = e.clientX;
        currentWidth.current = width;
        setDragging(true);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (dragging) {
            const deltaX = e.clientX - startX.current;
            const newWidth = currentWidth.current + deltaX;

            onResize(newWidth);
        }
    };

    const handleMouseUp = () => {
        if (dragging) {
            setDragging(false);
        }
    };

    useEffect(() => {
        if (dragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        } else {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);

    return (
        <th
            className={cn(className, styles.wrapper)}
            style={{ width }}
            {...restProps}
        >
            <div className={styles.cellContainer}>
                {children}
                <div
                    className={cn(styles.resizeHandle, {
                        [styles.resizeHandleActive]: dragging,
                    })}
                    onMouseDown={handleMouseDown}
                />
            </div>
        </th>
    );
};
