import { cloneElement } from 'react';
import { Tag } from 'antd';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { invoicesHelpers } from 'utils/helpers/invoices';
import type { TComponent } from 'utils/types/component';

import type { IPaymentInvoice } from 'models/Invoice/Payment';
import { PAYMENT_INVOICE_STATUS_INVOICE } from 'models/Invoice/Payment';

interface IStatusProps {
    status: IPaymentInvoice['status'];
}

const statusMap: Record<IPaymentInvoice['status'], ReactElement> = {
    [PAYMENT_INVOICE_STATUS_INVOICE.DECLINED]: <Tag color='red' />,
    [PAYMENT_INVOICE_STATUS_INVOICE.PROCESSING]: <Tag color='orange' />,
    [PAYMENT_INVOICE_STATUS_INVOICE.SUCCEED]: <Tag color='green' />,
    [PAYMENT_INVOICE_STATUS_INVOICE.CANCELED]: <Tag color='red' />,
    [PAYMENT_INVOICE_STATUS_INVOICE.NEW]: <Tag color='blue' />,
};

export const Status: TComponent<IStatusProps> = ({ status }) => {
    const { t } = useTranslation();

    const key = invoicesHelpers.getPaymentStatus(status);

    const tagElement = statusMap[status];

    return tagElement ? cloneElement(tagElement, { children: t(key) }) : null;
};
