import { useState } from 'react';
import type { ReactElement } from 'react';
import { ExportButton } from 'ui/ExportButton/ExportButton';
import type { TComponent } from 'utils/types/component';

import { InvoicesExportModal } from '../InvoicesExportModal';

interface IExportButtonProps {
    sendReport: (email: string) => void;
}

export const InvoicesExportModalButton: TComponent<
    IExportButtonProps,
    ReactElement
> = (props) => {
    const { sendReport } = props;

    const [isModalOpened, setIsModalOpened] = useState(false);

    const handleClick = () => {
        setIsModalOpened(true);
    };

    return (
        <>
            <ExportButton onClick={handleClick} />

            {isModalOpened && (
                <InvoicesExportModal
                    onClose={() => setIsModalOpened(false)}
                    onSave={sendReport}
                />
            )}
        </>
    );
};
