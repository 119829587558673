import type { TSettlementFindBody } from 'api/merchants/historySettlements/types';
import { SETTLEMENTS_CONSTANTS } from 'utils/constants/settlements';
import { dateHelpers } from 'utils/helpers/dateHelpers';

import type { IFilterForm } from '../types';

const getFiltersData = (data: IFilterForm) => {
    if (!Object.keys(data).length) {
        return;
    }

    const result: TSettlementFindBody = {};

    if (data.startDate) {
        result.startDate = dateHelpers.formatTo(
            dateHelpers.formatToUtc(data.startDate).toISOString(),
            SETTLEMENTS_CONSTANTS.FILTER_DATE_FORMAT,
        );
    }

    if (data.endDate) {
        result.endDate = dateHelpers.formatTo(
            dateHelpers.formatToUtc(data.endDate).toISOString(),
            SETTLEMENTS_CONSTANTS.FILTER_DATE_FORMAT,
        );
    }

    if (data.currencyCodes) {
        result.currencies = data.currencyCodes;
    }

    if (data.types) {
        result.types = data.types;
    }

    return result;
};

export const filtersHelpers = { getFiltersData };
