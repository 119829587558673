import { useState } from 'react';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import type { DefaultOptionType } from 'antd/lib/select';
import { selectHelpers } from 'utils/helpers/selectHelpers';
import type { TOptions } from 'utils/types/select';

import { useEffectWithoutInitCall } from 'components/utils/hooks/useEffectWithoutInitCall';

interface ISingleSelectProps<T>
    extends Omit<
        SelectProps<T, DefaultOptionType>,
        'value' | 'options' | 'onChange'
    > {
    options: TOptions<T>;
    value?: T | null;
    isLoading?: boolean;
    allLabel?: string;
    className?: string;
    placeholder?: string;
    onChange?: (value: T | null) => void;
}

const filterOption = (input: string, option?: DefaultOptionType) =>
    selectHelpers.doesOptionIncludeInput(option, input, 'children');

const SingleSelect = <T extends string | number>(
    props: ISingleSelectProps<T>,
) => {
    const {
        placeholder,
        className,
        options,
        value: valueFromProps,
        onChange,
        isLoading = false,
        ...restProps
    } = props;

    const [value, setValue] = useState<T | undefined | null>(valueFromProps);

    useEffectWithoutInitCall(() => {
        if (valueFromProps !== value) {
            setValue(valueFromProps);
        }
    }, [valueFromProps]);

    const handleChange = (newValue: T) => {
        onChange?.(newValue);
        setValue(newValue);
    };

    return (
        <Select
            value={value}
            onChange={handleChange}
            className={className}
            placeholder={placeholder}
            loading={isLoading}
            filterOption={filterOption}
            {...restProps}
        >
            {options.map(({ label, value: optionValue }) => (
                <Select.Option key={optionValue} value={optionValue}>
                    {label}
                </Select.Option>
            ))}
        </Select>
    );
};

export default SingleSelect;
