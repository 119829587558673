import { AbbreviatedText } from 'ui/AbbreviatedText';
import Clipboard from 'ui/Clipboard';
import { invoicesHelpers } from 'utils/helpers/invoices';
import type { TComponent } from 'utils/types/component';

import type { IPayoutInvoice } from 'models/Invoice/Payout';

import styles from './IdentityCell.module.scss';

interface IIdentityCellProps {
    record: IPayoutInvoice;
}

export const IdentityCell: TComponent<IIdentityCellProps> = (props) => {
    const { record } = props;

    const identity = record.identity;

    return (
        <Clipboard className={styles.fulle} textToCopy={identity}>
            <AbbreviatedText
                text={identity}
                formatFunction={invoicesHelpers.formatIdentity}
                className={styles.text}
            />
        </Clipboard>
    );
};
