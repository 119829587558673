import { Dropdown, Space, Typography } from 'antd';
import cn from 'classnames';
import type { ReactNode } from 'react';
import type { TComponent } from 'utils/types/component';

import styles from './UserBadge.module.scss';

interface IUserBadgeProps {
    username: string;
    children?: ReactNode;
    avatar?: string;
    isDisabled?: boolean;
}

export const UserBadge: TComponent<IUserBadgeProps> = (props) => {
    const { avatar, username, isDisabled, children } = props;

    const hasChildren = !!children;

    return (
        <Dropdown
            dropdownRender={() => children}
            disabled={!hasChildren}
            trigger={['click']}
        >
            <Space
                className={cn(styles.userBadge, {
                    [styles.disabled]: isDisabled,
                })}
                onClick={(e) => e.preventDefault()}
            >
                {avatar ? (
                    <img
                        className={styles.avatar}
                        src={avatar}
                        alt='User avatar'
                    />
                ) : (
                    <div className={styles.userNameContainer}>
                        <Typography.Text>
                            {username ? username.charAt(0).toUpperCase() : ''}
                        </Typography.Text>
                    </div>
                )}
                <Typography.Text className={styles.username}>
                    {username}
                </Typography.Text>
            </Space>
        </Dropdown>
    );
};
