import Clipboard from 'ui/Clipboard';
import { invoicesHelpers } from 'utils/helpers/invoices';
import type { TComponent } from 'utils/types/component';

import { AbbreviatedText } from 'components/ui/AbbreviatedText';

import type { IPaymentInvoice } from 'models/Invoice/Payment';

import styles from './IdentityCell.module.scss';

interface IIdentityCellProps {
    record: IPaymentInvoice;
}

export const IdentityCell: TComponent<IIdentityCellProps> = (props) => {
    const { record } = props;

    const identity = record.identity;

    return (
        <Clipboard className={styles.full} textToCopy={identity}>
            <AbbreviatedText
                text={identity}
                formatFunction={invoicesHelpers.formatIdentity}
                className={styles.text}
            />
        </Clipboard>
    );
};
